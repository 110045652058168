<template>
  <div class="bridge">
    <div class="bridge-top">
      <slot name="top"></slot>
    </div>

    <div class="bridge-list">
      <div class="bridge-list__top-gradient"></div>

      <div class="bridge-list__inner" ref="scrollContainer">
        <slot name="list"></slot>
      </div>

      <div class="bridge-list__bottom-gradient"></div>
    </div>

    <div class="bridge-bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BridgeLayout',
  methods: {
    resetScroll() {
      this.$refs.scrollContainer.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.bridge {
  position: relative;
  min-height: calc(100vh - 64px);

  &-bottom,
  &-top {
    z-index: 2;
  }

  &-list,
  &-bottom,
  &-top {
    position: absolute;
    right: 0;
    left: 0;
  }

  &-bottom {
    bottom: 0;
  }

  &-top {
    top: 0;
  }

  &-list {
    bottom: 56px;
    top: 116px;

    &__column {
      position: relative;
      padding: 50px 0;
    }

    &__inner {
      overflow: auto;
      width: 100%;
      max-height: calc(100vh - 240px);
      padding: 24px 12px;
    }

    &__top-gradient,
    &__bottom-gradient {
      height: 25px;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    &__top-gradient {
      background: linear-gradient(180deg, rgba(236, 239, 241, 1) 50%, rgba(0, 212, 255, 0) 100%);
      top: 0;
    }

    &__bottom-gradient {
      background: linear-gradient(0deg, rgba(236, 239, 241, 1) 50%, rgba(0, 212, 255, 0) 100%);
      bottom: 0;
    }
  }
}
</style>
