<template>
  <v-dialog v-model="dialog" max-width="90%" no-click-animation>
    <v-card>
      <v-tabs v-model="tab" grow color="primary">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#date"> Select Date </v-tab>
        <v-tab href="#batch"> Select Batch ID </v-tab>
      </v-tabs>
      <v-card-title>Cancel Trip Requests</v-card-title>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="date" class="tab-wrapper">
          <v-card flat>
            <v-card-text>
              <v-row justify="center" class="pb-4">
                <v-col cols="12" md="4">
                  <date-picker label="Date" v-model="date"></date-picker>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="reason"
                    outlined
                    label="Please indicate the reason for canceling these trips"
                  ></v-text-field>
                </v-col>
              </v-row>
              <template v-if="filteredTripRequests.length > 0">
                <v-row dense class="mt-n8">
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="3">
                    <v-switch class="mx-2 right" v-model="allExpanded" label="Expand All"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-for="tr of filteredTripRequests" :key="tr.id + 9999999" dense>
                    <selectable-trip-request
                      :value="selected"
                      @input="handleSelected"
                      :tripRequest="tr"
                      :allExpanded="allExpanded"
                    ></selectable-trip-request>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" value="batch" class="tab-wrapper">
          <v-card flat>
            <v-card-text>
              <v-row justify="center" dense>
                <v-col cols="12" md="4">
                  <v-select
                    label="Batch ID"
                    v-model="batchId"
                    :items="batchIds"
                    item-text="label"
                    item-value="value"
                    outlined
                    @change="handleBatchIdSelected"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="reason"
                    outlined
                    label="Please indicate the reason for canceling these trips"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row justify="center" dense class="mt-n8">
                <v-col cols="12" md="6">
                  <v-radio-group v-model="filter" row @change="applyFilter">
                    <v-radio label="All Trip Dates" value="all"></v-radio>
                    <v-radio label="Trips From This Day Forward" value="future"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row> -->
              <template v-if="filteredTripRequests.length > 0">
                <v-row dense class="mt-n8">
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="3">
                    <v-switch class="mx-2 right" v-model="allExpanded" label="Expand All"></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" v-for="tr of filteredTripRequests" :key="tr.id + 9999999" dense>
                    <selectable-trip-request
                      :value="selected"
                      @input="handleSelected"
                      :tripRequest="tr"
                      :allExpanded="allExpanded"
                    ></selectable-trip-request>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Close </v-btn>
        <v-btn color="primary" @click="cancel" :loading="saving" :disabled="saving"> Cancel Trips </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CANCEL_TRIPS } from '@/store/modules/TripRequest/actions';
import DatePicker from '@/components/DatePicker';
import SelectableTripRequest from '@/components/SelectableTripRequest.vue';

export default {
  name: 'CancelTrips',
  inject: ['eventHub'],
  components: { DatePicker, SelectableTripRequest },
  props: {
    tripRequests: Array,
  },
  data() {
    return {
      dialog: false,
      tab: 'date',
      date: null,
      batchId: 0,
      filter: 'all',
      selected: [],
      reason: '',
      filteredTripRequests: [],
      allExpanded: false,
      saving: false,
    };
  },
  computed: {
    ...mapGetters('user', ['users', 'usersById', 'userEmails']),
    batchIds() {
      return this.tripRequests
        .filter((e) => e.batchId)
        .map((e) => ({ label: `Batch #${e.batchId}`, value: e.batchId }));
    },
  },
  methods: {
    ...mapActions('tripRequest', [CANCEL_TRIPS]),
    async cancel() {
      try {
        const ok = await this.$myconfirm('Are you sure you want to cancel these trips?');
        this.saving = true;
        if (ok) {
          const r = await this.cancelTrips({
            ids: this.selected,
            reason: this.reason,
          });
          if (r && r.done) {
            this.$emit('refreshTripRequests');
            this.$myalert.success('Trips Canceled');
            this.close();
          }
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
    handleBatchIdSelected() {
      this.filteredTripRequests = this.tripRequests.filter(
        (e) => (e.batchId == this.batchId || e.id == this.batchId) && (e.status == 1 || e.status == -1)
      );
      this.selected = this.filteredTripRequests.map((e) => e.id);
    },
    handleDateSelected() {
      this.filteredTripRequests = this.tripRequests.filter(
        (e) => e.leaveDate == this.date && (e.status == 1 || e.status == -1)
      );
      this.selected = this.filteredTripRequests.map((e) => e.id);
    },
    handleSelected(ids) {
      this.selected = ids;
    },
    close() {
      this.dialog = false;
      this.selected = [];
      this.tab = 'date';
      this.date = null;
      this.batchId = 0;
      this.filter = 'all';
      this.reason = '';
      this.filteredTripRequests = [];
      this.allExpanded = false;
    },
  },
  watch: {
    date(value) {
      if (value) this.handleDateSelected();
    },
    tab() {
      this.filteredTripRequests = [];
      this.batchId = 0;
      this.date = null;
    },
  },
};
</script>

<style lang="scss">
.tab-wrapper {
  max-height: 700px;
  overflow-y: auto;
}
</style>
