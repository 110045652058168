<template>
  <v-dialog v-model="dialog" max-width="800px" no-click-animation>
    <v-card>
      <v-card-title v-if="tripRequest.id">Change Trip Request #{{ tripRequest.id }} Submitter</v-card-title>
      <v-card-title v-else>Change Trip Requests' Submitter</v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12" md="12" v-if="!tripRequest.id">
            <v-select
              label="Old Submitter"
              v-model="oldSubmitter"
              :items="submitters"
              :item-text="getUserText"
              item-value="id"
              outlined
              return-object
            >
            </v-select> </v-col
          ><v-col cols="12" md="12">
            <v-select
              label="New Submitter"
              v-model="newSubmitter"
              :items="users"
              :item-text="getUserText"
              item-value="id"
              outlined
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        <v-btn color="success" dark @click="saveChange"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CHANGE_TRIP_REQUEST_SUBMITTER } from '@/store/modules/TripRequest/actions';

export default {
  name: 'ChangeSubmitter',
  inject: ['eventHub'],
  components: {},
  props: {
    tripRequest: Object,
  },
  data() {
    return {
      dialog: false,
      oldSubmitter: {},
      newSubmitter: {},
    };
  },
  computed: {
    ...mapGetters('user', ['users', 'usersById', 'userEmails']),
    ...mapGetters('tripRequest', ['tripRequests']),
    submitters() {
      return this.tripRequests.map((e) => this.usersById[e.submittedUser]);
    },
  },
  methods: {
    ...mapActions('tripRequest', [CHANGE_TRIP_REQUEST_SUBMITTER]),
    getUserText(user) {
      return `${user.firstName} ${user.lastName} (${user.email})`;
    },
    async saveChange() {
      if (this.oldSubmitter.id == this.newSubmitter.id) this.$myalert.error('Select a new submitter');
      else
        try {
          const r = await this.changeSubmitter({
            oldSubmitter: this.oldSubmitter.id ? this.oldSubmitter : this.usersById[this.tripRequest.submittedUser],
            newSubmitter: this.newSubmitter,
            tripRequestId: this.tripRequest.id || 0,
          });
          if (r) {
            if (this.tripRequest && this.tripRequest.id)
              this.$myalert.success(`Trip Request #${this.tripRequest.id} submitter changed`);
            else this.$myalert.success(`Trip Requests' submitter changed`);
            this.oldSubmitter = {};
            this.newSubmitter = {};
            this.$emit('refreshTripRequests');
          } else this.$myalert.success('No Trips require changing');
          this.dialog = false;
        } catch (error) {
          this.$myalert.error(error.message);
        }
    },
  },
};
</script>

<style lang="scss"></style>
