<template>
  <v-dialog v-model="dialog" max-width="90%" no-click-animation>
    <v-card>
      <v-card-title>Mass Assign to Recurring Trips</v-card-title>
      <v-card-text class="list-wrapper">
        <v-row dense>
          <v-col cols="12" md="4">
            <v-select
              label="Batch ID"
              v-model="batchId"
              :items="batchIds"
              item-text="label"
              item-value="value"
              outlined
              @change="handleBatchIdSelected"
            >
            </v-select>
            <v-radio-group v-model="filter" row @change="applyFilter" class="mt-n4">
              <v-radio label="All Trips" value="all"></v-radio>
              <v-radio label="Need Vehicle(s)/Driver(s)" value="pending"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model.number="numVehicles"
              label="Change # of Vehicles Needed"
              type="number"
              min="0"
              :hint="currentNumVehicles"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              label="Change Destination"
              v-model="destinationId"
              :items="destinations"
              item-text="name"
              item-value="id"
              outlined
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-for="(index, rowIndex) of numberOfVehicles" dense :key="index">
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <v-select
              :label="`Driver ${index}`"
              v-model="driverId[rowIndex]"
              :items="filteredDrivers(rowIndex)"
              :item-text="getDriverText"
              item-value="id"
              outlined
              @change="handleDriverSelected(rowIndex)"
            >
              <template v-slot:append-outer>
                <v-icon v-if="driverId[rowIndex]" color="error" @click="clearSelection('driver', rowIndex)"
                  >mdi-close-circle</v-icon
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :label="`Vehicle ${index}`"
              v-model="vehicleId[rowIndex]"
              :items="filteredVehicles(rowIndex)"
              item-text="name"
              item-value="id"
              outlined
            >
              <template v-slot:append-outer>
                <v-icon v-if="vehicleId[rowIndex]" color="error" @click="clearSelection('vehicle', rowIndex)"
                  >mdi-close-circle</v-icon
                >
              </template>
            </v-select>
          </v-col>
        </v-row>
        <template v-if="filteredTripRequests.length > 0">
          <v-row dense class="mt-n8">
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
              <v-switch class="mx-2 right" v-model="allExpanded" label="Expand All"></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="loading" dense>
            <v-progress-circular :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
          </v-row>
          <v-row v-else>
            <v-col cols="12" v-for="tr of filteredTripRequests" :key="tr.id + 9999999" dense>
              <selectable-trip-request
                :value="selected"
                @input="handleSelected"
                :tripRequest="tr"
                :allExpanded="allExpanded"
              ></selectable-trip-request>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Close </v-btn>
        <v-btn color="primary" @click="assign" :loading="saving" :disabled="buttonDisabled">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { BATCH_ASSIGN, GET_ASSIGNMENTS } from '@/store/modules/Assignment/actions';
import { GET_TRIP_REQUESTS } from '@/store/modules/TripRequest/actions';
import SelectableTripRequest from './SelectableTripRequest.vue';

export default {
  name: 'BatchAssign',
  inject: ['eventHub'],
  components: { SelectableTripRequest },
  props: {},
  data() {
    return {
      dialog: false,
      batchId: 0,
      filter: 'all',
      selected: [],
      filteredTripRequests: [],
      allExpanded: false,
      driverId: [],
      vehicleId: [],
      destinationId: 0,
      numVehicles: 0,
      saving: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('destination', ['destinations']),
    ...mapGetters('user', ['users', 'usersById', 'userEmails']),
    batchIds() {
      return this.tripRequests
        .filter((e) => e.batchId)
        .map((e) => ({ label: `Batch #${e.batchId}`, value: e.batchId }));
    },
    numberOfVehicles() {
      return this.numVehicles || (this.filteredTripRequests.length ? this.filteredTripRequests[0].numVehicles : 1);
    },
    currentNumVehicles() {
      const text = 'Current # of vehicles: ';
      return text + (this.filteredTripRequests.length ? this.filteredTripRequests[0].numVehicles : 0);
    },
    buttonText() {
      const hasDriverOrVehicle = this.driverId.length || this.vehicleId.length;
      const hasNumVehiclesOrDestination = this.numVehicles || this.destinationId;

      if (hasDriverOrVehicle && hasNumVehiclesOrDestination) {
        return 'Assign & Save';
      } else if (hasDriverOrVehicle) {
        return 'Assign';
      } else if (hasNumVehiclesOrDestination) {
        return 'Save';
      } else {
        return 'Assign';
      }
    },
    buttonDisabled() {
      if (!this.driverId.length && !this.vehicleId.length && !this.numVehicles && !this.destinationId) return true;
      return this.saving || !this.batchId;
    },
    confirmationMessage() {
      if (this.buttonText == 'Assign & Save') return 'this assignment & changes';
      if (this.buttonText == 'Save') return 'these changes';
      return 'this assignment';
    },
  },
  methods: {
    ...mapActions('assignment', [BATCH_ASSIGN, GET_ASSIGNMENTS]),
    ...mapActions('tripRequest', [GET_TRIP_REQUESTS]),
    async assign() {
      try {
        if (
          !this.selected.length ||
          (!this.vehicleId.length && !this.driverId.length && !this.destinationId && !this.numVehicles)
        )
          this.$myalert.error('Please select at least one trip and select assignment or update information');
        else {
          const ok = await this.$myconfirm(`Are you sure you want to make ${this.confirmationMessage} to these trips?`);
          this.saving = true;
          if (ok) {
            const r = await this.batchAssign({
              tripRequestIds: this.selected,
              vehicleId: this.vehicleId,
              driverId: this.driverId,
              destinationId: this.destinationId,
              numVehicles: this.numVehicles,
            });
            if (r && r.done) {
              this.getTripRequests();
              // this.getAssignments({ includeStops: true });
              this.$myalert.success('Assignments made');
              this.selected = [];
              this.loading = true;
            }
          }
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
    handleBatchIdSelected() {
      this.filteredTripRequests = this.tripRequests.filter(
        (e) => (e.id == this.batchId || e.batchId == this.batchId) && e.status == 1
      );
      this.selected = this.filteredTripRequests.map((e) => e.id);
      if (this.filter == 'pending') this.applyFilter();
    },
    handleDriverSelected(index) {
      const assignedVehicleId = this.driversById[this.driverId[index]].assignedVehicleId;

      if (assignedVehicleId && this.vehicleId[index] !== assignedVehicleId) {
        const vehicles = this.filteredVehicles(index);

        const matchingVehicle = vehicles.find((vehicle) => vehicle.id === assignedVehicleId);

        if (matchingVehicle) {
          this.vehicleId[index] = matchingVehicle.id;
        }
      }
    },
    applyFilter() {
      if (this.filter == 'all') this.handleBatchIdSelected();
      else if (this.filter == 'pending')
        this.filteredTripRequests = this.filteredTripRequests.filter(
          (e) => e.canAssign && (e.pendingDrivers || e.pendingVehicles)
        );
      this.selected = this.selected.filter((e) => this.filteredTripRequests.map((f) => f.id).includes(e));
    },
    handleSelected(ids) {
      this.selected = ids;
    },
    close() {
      this.dialog = false;
      this.selected = [];
      this.batchId = 0;
      this.filter = 'all';
      this.filteredTripRequests = [];
      this.driverId = [];
      this.vehicleId = [];
      this.destinationId = 0;
      this.numVehicles = 0;
      this.allExpanded = false;
    },
    getDriverText(driver) {
      return driver ? `${driver.firstName} ${driver.lastName}` : '';
    },
    refreshTripList() {
      this.filteredTripRequests = this.tripRequests.filter(
        (e) => (e.id == this.batchId || e.batchId == this.batchId) && e.status == 1
      );
      this.loading = false;
    },
    filteredDrivers(index) {
      if (!this.driverId) return [];

      const selectedDriverIds = this.driverId.reduce((selectedIds, id, i) => {
        if (i !== index && id !== null) {
          selectedIds.push(id);
        }
        return selectedIds;
      }, []);

      return this.drivers.filter((driver) => !selectedDriverIds.includes(driver.id));
    },
    filteredVehicles(index) {
      if (!this.vehicleId) return [];

      const selectedVehicleIds = this.vehicleId.reduce((selectedIds, id, i) => {
        if (i !== index && id !== null) {
          selectedIds.push(id);
        }
        return selectedIds;
      }, []);

      return this.vehicles.filter((vehicle) => !selectedVehicleIds.includes(vehicle.id));
    },
    clearSelection(type, rowIndex) {
      if (type === 'driver') {
        this.$set(this.driverId, rowIndex, null);
      } else if (type === 'vehicle') {
        this.$set(this.vehicleId, rowIndex, null);
      }
    },
  },
  watch: {
    dialog(value) {
      if (!value) this.close();
      this.filteredTripRequests = [];
    },
    tripRequests(value) {
      this.refreshTripList();
    },
  },
};
</script>

<style scoped lang="scss">
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
.list-wrapper {
  padding-top: 8px !important;
  max-height: 700px;
  overflow-y: auto;
}
</style>
