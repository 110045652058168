<template>
  <v-dialog v-model="dialog" max-width="1000px" no-click-animation>
    <v-card>
      <v-card-text class="pt-5">
        <v-data-table fixed-header :headers="headers" :items="items" item-key="id" sort-by="time" disable-sort>
          <template v-slot:[`item.time`]="{ item }">
            {{ format(new Date(item.timestamp * 1000), 'PP p') }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'TripHistory',
  inject: ['eventHub'],
  components: {},
  props: {
    tripRequest: Object,
  },
  data() {
    return {
      format,
      dialog: false,
      headers: [
        { text: 'Date', value: 'time' },
        { text: 'User', value: 'user' },
        { text: 'Action', value: 'action' },
        { text: 'Comment', value: 'comment' },
      ],
      approvalActions: {
        '-1': 'Denied',
        0: 'Changes Requested',
        1: 'Approved',
      },
    };
  },
  computed: {
    ...mapGetters('approvalLevel', ['approvalLevelsById']),
    items() {
      let history = [];
      if (this.tripRequest.auditHistory)
        history = this.tripRequest.auditHistory.map((e) => ({
          timestamp: e.timestamp,
          user: e.displayName,
          action: e.description,
        }));
      if (this.tripRequest.approval)
        history = history.concat(
          this.tripRequest.approval.history.map((e) => ({
            timestamp: e.timestamp,
            user: e.user,
            action: `${this.approvalLevelsById[e.approvalLevelId].name} - ${this.approvalActions[e.approved]}`,
            comment: e.comments,
          }))
        );
      return history.sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
