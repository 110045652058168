<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation>
    <v-card>
      <v-card-title>Select a Date Range</v-card-title>
      <v-card-text class="pt-5">
        <v-row justify="center" class="pb-4">
          <v-col cols="12" md="4">
            <date-picker label="Start Date" v-model="startDate"></date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <date-picker label="End Date" v-model="endDate"></date-picker>
          </v-col>
        </v-row>
        <v-row v-for="am in activeMessages" :key="am.id" dense>
          <v-col cols="12" md="12">
            <v-alert outlined :type="am.alertType || 'info'" text>{{ am.message }}</v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="apply" :disabled="disable"> Apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '@/components/DatePicker';

export default {
  name: 'DateRange',
  inject: ['eventHub'],
  components: { DatePicker },
  props: {
    filter: String,
  },
  data() {
    return {
      dialog: false,
      startDate: null,
      endDate: null,
      activeMessages: [],
      disable: true,
    };
  },
  computed: {
    filterEvent() {
      if (this.filter == 'trip') return 'addFilterTripRequests';
      else if (this.filter == 'assignment') return 'addFilterAssignments';
      else return '';
    },
  },
  methods: {
    apply() {
      this.eventHub.$emit(this.filterEvent, {
        name: 'Select Dates',
        search: {
          field: 'dateRange',
          value: `${this.startDate} - ${this.endDate}`,
          text: `Dates ${this.startDate} - ${this.endDate}`,
        },
      });
      this.close();
    },
    checkTripDates() {
      if (this.startDate && !this.endDate) this.endDate = this.startDate;
      this.activeMessages = [];
      this.disable = false;
      if (this.startDate && this.endDate && this.endDate < this.startDate) {
        this.activeMessages.push({
          message: 'Return date cannot be before leave date',
          alertType: 'error',
        });
        this.disable = true;
      }
    },
    close() {
      this.dialog = false;
      this.startDate = null;
      this.endDate = null;
    },
  },
  watch: {
    startDate(value) {
      if (value) this.checkTripDates();
    },
    endDate(value) {
      if (value) this.checkTripDates();
    },
  },
};
</script>

<style lang="scss"></style>
