<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation persistent>
    <v-card>
      <v-card-title>Cancel Trip</v-card-title>
      <v-card-text class="pt-5">
        <v-row v-if="tripCancellationMessage" dense>
          <v-col cols="12" md="12">
            <v-alert outlined type="info" text>
              {{ tripCancellationMessage }}
            </v-alert>
          </v-col>
        </v-row>
        <v-form ref="form" @submit="cancel">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="reason"
                outlined
                label="Please indicate the reason for canceling this trip"
                :rules="[(v) => !!v || 'Reason is required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="preventCancellationMessage">
            <v-col cols="12" md="12">
              <v-alert outlined type="info" text>{{ preventCancellationMessage }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Back </v-btn>
        <v-btn
          color="primary"
          @click="cancel()"
          :loading="saving"
          :disabled="saving || !reason || !!preventCancellationMessage"
        >
          Cancel Trip
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { differenceInDays } from 'date-fns';
import { CANCEL_TRIPS, GET_TRIP_REQUEST } from '@/store/modules/TripRequest/actions';

export default {
  name: 'TripCancel',
  inject: ['eventHub'],
  components: {},
  props: {
    tripRequest: Object,
  },
  data() {
    return {
      dialog: false,
      preventCancellationMessage: null,
      reason: '',
      saving: false,
    };
  },
  created() {},
  methods: {
    ...mapActions('tripRequest', [CANCEL_TRIPS, GET_TRIP_REQUEST]),
    ...mapMutations('tripRequest', ['updateTripRequest']),
    ...mapMutations('assignment', ['removeAssignmentsByTripRequestId']),
    check() {
      this.preventCancellationMessage = null;

      if (this.me.is.superAdmin || this.me.is.transportationAdmin) {
        return;
      }

      if (this.tripRequestConfig.display.limitReschedule) {
        const tripDayDifferenceFromToday = differenceInDays(new Date(this.tripRequest.leaveDate), new Date());
        if (tripDayDifferenceFromToday < this.tripRequestConfig.display.limitRescheduleDays) {
          this.preventCancellationMessage = this.tripRequestConfig.display.limitRescheduleMessage;
        }
      }
    },
    async cancel() {
      this.saving = true;
      try {
        const r = await this.cancelTrips({
          ids: [this.tripRequest.id],
          reason: this.reason,
        });
        if (r && r.done) {
          const updated = await this.getTripRequest(this.tripRequest.id);
          this.updateTripRequest(updated.tripRequest);
          this.removeAssignmentsByTripRequestId(this.tripRequest.id);
          this.dialog = false;
          this.$myalert.success('Trip Canceled');
          this.$router.push('/trip-requests');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['tripRequestConfig']),
    tripCancellationMessage() {
      return this.tripRequestConfig?.messages?.tripCancelation || null;
    },
  },
  watch: {
    tripRequest() {
      this.check();
    },
  },
};
</script>

<style lang="scss"></style>
