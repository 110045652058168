<template>
  <v-dialog v-model="dialog" max-width="800px" no-click-animation persistent>
    <v-card>
      <v-card-title>Reschedule Trip Request</v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" @submit="reschedule">
          <v-row>
            <v-col cols="12" md="4">
              <date-picker
                ref="leaveDate"
                label="Leave Date"
                v-model="leaveDate"
                :rules="[() => !!leaveDate || 'This field is required']"
                required
                :hint="`Currently ${tripRequest.leaveDate}`"
                :persistentHint="true"
              ></date-picker>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="4">
              <time-picker
                ref="leaveTime"
                label="Leave Time"
                v-model="leaveTime"
                :rules="[() => !!leaveTime || 'This field is required']"
                required
                :hint="`Currently ${readableTime(tripRequest.leaveTime)}`"
                :persistentHint="true"
              ></time-picker>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <date-picker
                ref="returnDate"
                label="Return Date"
                v-model="returnDate"
                :rules="[() => !!returnDate || 'This field is required']"
                required
                :hint="`Currently ${tripRequest.returnDate}`"
                :persistentHint="true"
              ></date-picker>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="4">
              <time-picker
                ref="returnTime"
                label="Return Time"
                v-model="returnTime"
                :rules="[() => !!returnTime || 'This field is required']"
                required
                :hint="`Currently ${readableTime(tripRequest.returnTime)}`"
                :persistentHint="true"
              ></time-picker>
            </v-col>
            <v-spacer></v-spacer>
            <template v-if="me.is.superAdmin || me.is.transportationAdmin">
              <v-col cols="12" md="12" class="py-0">
                <v-radio-group v-model="retainAssignments" row>
                  <template v-slot:label>
                    <div>Retain current vehicle and driver assignments?</div>
                  </template>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <div class="text-subtitle-1 font-weight-bold amber--text text--darken-2">
                  Please note that by clicking "Yes" here, the vehicle and driver availability will not be checked
                </div>
              </v-col>
            </template>
          </v-row>
          <v-row v-for="am in [...activeMessages]" :key="am.id" dense>
            <v-col cols="12" md="12">
              <v-alert outlined :type="am.alertType || 'info'" text>{{ am.message }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="success" @click="reschedule()" :disabled="prevent" :loading="saving"> Reschedule </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { RESCHEDULE_TRIP } from '@/store/modules/TripRequest/actions';
import { GET_CONFIG, GET_TRIP_DATE_TIMES } from '@/store/modules/Config/actions';
import { uniq, uniqBy } from 'lodash';
import { differenceInDays } from 'date-fns';
import { readableTime } from '@/util';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';

export default {
  name: 'RescheduleTrip',
  inject: ['eventHub'],
  mixins: [],
  components: { TimePicker, DatePicker },
  props: {
    tripRequest: Object,
  },
  data() {
    return {
      readableTime,
      dialog: false,
      leaveDate: '',
      returnDate: '',
      leaveTime: '',
      returnTime: '',
      retainAssignments: false,
      activeMessages: new Set(),
      prevent: false,
      blockedDates: [],
      specialDates: [],
      leadDays: {},
      saving: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['tripRequestConfig', 'tripDateTimes', 'leadDaysConfig']),
  },
  created() {
    this.fetchItems();
  },
  methods: {
    ...mapActions('tripRequest', [RESCHEDULE_TRIP]),
    ...mapActions('config', [GET_CONFIG, GET_TRIP_DATE_TIMES]),
    async fetchItems() {
      const dateTimes = this.tripDateTimes ?? await this.getTripDateTimes();
      this.tripTimeMessages = dateTimes.filter((e) => e.type === 'time');
      this.blockedDates = dateTimes.filter((e) => e.type === 'blocked');
      this.specialDates = dateTimes.filter((e) => e.type === 'special');
      this.leadDays = this.leadDaysConfig ?? await this.getConfig('leadDays');
    },
    async reschedule() {
      if (!this.leaveDate.length || !this.returnDate.length || !this.leaveTime.length || !this.returnTime.length) {
        this.$myalert.error('Please complete each date & time field');
        return;
      }
      this.saving = true;
      try {
        const obj = {
          tripRequestId: this.tripRequest.id,
          leaveDate: this.leaveDate,
          returnDate: this.returnDate,
          leaveTime: this.leaveTime,
          returnTime: this.returnTime,
          retainAssignments: this.retainAssignments,
        };
        const r = await this.rescheduleTrip(obj);
        if (r && r.done) {
          this.$emit('refreshTripRequest');
          this.dialog = false;
          this.$myalert.success('Trip Rescheduled');
          this.reset();
          this.$emit('refreshTripRequests');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.saving = false;
    },
    checkTripTimes(times) {
      if (this.tripRequest.tripTypeId) {
        times = times.filter((e) => e);
        for (let time of times)
          for (let ttm of this.tripTimeMessages)
            if (ttm.tripTypeIds.includes(this.tripRequest.tripTypeId) && ttm.begin <= time && time <= ttm.end) {
              ttm.message = ttm.description;
              this.activeMessages.add(ttm);
            }
      }
    },
    checkTripDates(dates) {
      if (this.leaveDate && !this.returnDate) this.returnDate = this.leaveDate;

      if (this.leaveDate && this.returnDate) {
        if (this.returnDate < this.leaveDate) {
          this.activeMessages.add({
            message: 'Return date cannot be before leave date',
            alertType: 'error',
          });
          this.prevent = true;
        } else if (
          this.returnDate == this.leaveDate &&
          this.returnTime &&
          this.leaveTime.localeCompare(this.returnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) > 0
        ) {
          this.activeMessages.add({
            message: 'Return time cannot be before leave time',
            alertType: 'error',
          });
          this.prevent = true;
        }
      }

      if (this.tripRequest.tripTypeId) {
        dates = uniq(dates.filter((e) => e));
        for (let date of dates) {
          if (this.leadDays.general) {
            const ldConfig = this.tripRequest.outOfState ? this.leadDays.oos : this.leadDays.general;
            const activeMessagesArray = [...this.activeMessages];
            if (
              ldConfig.tripTypeIds.includes(this.tripRequest.tripTypeId) &&
              differenceInDays(new Date(date), new Date()) < ldConfig.numDays &&
              !activeMessagesArray.map((e) => e.id).includes('lead')
            ) {
              const message = {
                id: 'lead',
                message: `Lead time is ${ldConfig.numDays} days. ${
                  ldConfig.type == 'prevent' ? this.leadDays.preventSubmitMessage : this.leadDays.warningMessage
                }`,
                alertType: ldConfig.type == 'prevent' ? 'error' : 'info',
              };
              const isAdmin = this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
              if (isAdmin) {
                message.message += ' (As an admin you can still submit this trip request)';
                message.alertType = 'info';
              }
              this.activeMessages.add(message);
              if (ldConfig.type == 'prevent') this.prevent = !isAdmin;
            }
          }
          for (let bd of this.blockedDates)
            if (
              bd.tripTypeIds.includes(this.tripRequest.tripTypeId) &&
              bd.tripEventIds.some((e) => this.tripRequest.tripEventIds.includes(e)) &&
              bd.begin <= date &&
              date <= bd.end
            ) {
              bd.message = 'Trip date is blocked by District Office: ' + bd.description;
              bd.alertType = 'error';
              this.activeMessages.add(bd);
              this.prevent = true;
            }
          for (let sd of this.specialDates)
            if (sd.tripTypeIds.includes(this.tripRequest.tripTypeId) && sd.begin <= date && date <= sd.end) {
              sd.message = 'Reminder: ' + sd.description;
              this.activeMessages.add(sd);
            }
        }
      }
      //this.activeMessages = uniqBy(this.activeMessages, 'message');
    },
    check() {
      this.prevent = false;
      this.activeMessages = new Set();
      if (this.leaveDate || this.returnDate) this.checkTripDates([this.leaveDate, this.returnDate]);
      if (this.leaveTime || this.returnTime) this.checkTripTimes([this.leaveTime, this.returnTime]);
    },
    reset() {
      this.leaveDate = '';
      this.returnDate = '';
      this.leaveTime = '';
      this.returnTime = '';
      this.retainAssignments = false;
    },
  },
  watch: {
    leaveDate(value) {
      if (value && !this.returnDate) this.returnDate = value;
      this.check();
    },
    returnDate() {
      this.check();
    },
    leaveTime() {
      this.check();
    },
    returnTime() {
      this.check();
    },
    tripRequest() {
      this.check();
    },
  },
};
</script>

<style scoped></style>
