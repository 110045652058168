<template>
  <v-dialog v-model="dialog" no-click-animation fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" class="mb-6">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Trip Emails</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="auto" class="pt-6">
            <v-slide-group show-arrows>
              <v-slide-item v-for="(s, i) in sortOptions" :key="i">
                <v-btn
                  class="mx-2"
                  :input-value="!!s.order"
                  active-class="blue darken-2 white--text"
                  depressed
                  rounded
                  @click="setCurrentSort(i)"
                >
                  {{ s.label }}
                  <v-icon v-if="!!s.order">{{ s.order == 'desc' ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-col>
          <v-col cols="4">
            <v-select
              label="Email Sender/Recipient"
              v-model="filterEmail"
              :items="emailAddresses"
              outlined
              clearable
              @change="filterList"
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              label="Trip #"
              v-model="filterTrip"
              :items="tripNumbers"
              item-text="label"
              item-value="id"
              outlined
              clearable
              @change="filterList"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-data-table fixed-header :headers="headers" :items="filteredList" item-key="id" show-expand>
          <template v-slot:[`item.tripNumber`]="{ item }"> #{{ item.tripRequestId }} </template>
          <template v-slot:[`item.sent`]="{ item }">
            {{ format(fromUnixTime(item.timestamp), 'MMM d, yyyy @ h:mm aaa') }}
          </template>
          <template v-slot:[`item.from`]="{ item }">
            {{ usersById[item.userId]?.email ? usersById[item.userId]?.email :  'Legacy Requester'}}
          </template>
          <template v-slot:[`item.content`]="{ item }">
            <p class="truncate">{{ item.content }}</p>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <p class="keep-spaces" v-html="item.content"></p>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
import { uniq } from 'lodash';

export default {
  name: 'TripEmails',
  inject: ['eventHub'],
  components: {},
  props: {},
  data() {
    return {
      format,
      fromUnixTime,
      dialog: false,
      headers: [
        { text: 'Trip', value: 'tripNumber', sortable: false, width: 74 },
        { text: 'Sent', value: 'sent', sortable: false, width: 120 },
        { text: 'Subject', value: 'subject', sortable: false, width: 150 },
        { text: 'From', value: 'from', sortable: false },
        { text: 'To', value: 'sendTo', sortable: false },
        { text: 'Body', value: 'content', sortable: false },
      ],
      emails: [],
      sortOptions: [
        { label: 'Trip #', sortBy: 'tripRequestId' },
        { label: 'Sent', sortBy: 'timestamp' },
        { label: 'From', sortBy: 'from' },
        { label: 'To', sortBy: 'sendTo' },
      ],
      currentSort: 0,
      filteredList: [],
      filterEmail: '',
      filterTrip: 0,
    };
  },
  computed: {
    ...mapGetters('user', ['usersById', 'usersByEmail']),
    emailAddresses() {
      const from = this.emails.map((e) => this.usersById[e.userId]?.email ?? 'Legacy Requester');
      let to = [];
      this.emails.forEach((e) => {
        if (e.sendTo.includes(',')) to = to.concat(e.sendTo.split(','));
        else to.push(e.sendTo);
      });
      return uniq(from.concat(to).sort((a, b) => a.localeCompare(b)));
    },
    tripNumbers() {
      const ids = this.emails.map((e) => e.tripRequestId);
      return uniq(ids).map((e) => ({ id: e, label: '#' + e }));
    },
  },
  methods: {
    setCurrentSort(index) {
      this.currentSort = index;
      this.sortEmails();
    },
    sortEmails(maintain) {
      const option = this.sortOptions[this.currentSort];
      if (maintain) option.order = option.order || 'desc';
      else {
        if (!option.order) {
          this.sortOptions.forEach((e) => (e.order = null));
          option.order = 'desc';
        } else option.order = option.order == 'desc' ? 'asc' : 'desc';
      }

      if (option.order == 'desc')
        this.emails.sort((a, b) => {
          if (option.sortBy == 'from')
            return this.usersById[b.userId]?.email.localeCompare(this.usersById[a.userId]?.email ?? 'Legacy Requester');
          else if (option.sortBy == 'sendTo') return b[option.sortBy].localeCompare(a[option.sortBy]);
          else return b[option.sortBy] - a[option.sortBy];
        });
      else
        this.emails.sort((a, b) => {
          if (option.sortBy == 'from')
            return this.usersById[a.userId]?.email.localeCompare(this.usersById[b.userId]?.email ?? 'Legacy Requester');
          else if (option.sortBy == 'sendTo') return a[option.sortBy].localeCompare(b[option.sortBy]);
          else return a[option.sortBy] - b[option.sortBy];
        });
      this.filterList();
    },
    filterList() {
      this.filteredList = this.emails;
      if (this.filterEmail) this.filterByEmail();
      if (this.filterTrip) this.filterByTrip();
    },
    filterByEmail() {
      this.filteredList = this.emails.filter(
        (e) =>
          e.sendTo.includes(this.filterEmail) ||
          (this.usersByEmail[this.filterEmail] && e.userId == this.usersByEmail[this.filterEmail].id)
      );
    },
    filterByTrip() {
      this.filteredList = this.filteredList.filter((e) => e.tripRequestId == this.filterTrip);
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  max-width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
}
.keep-spaces {
  white-space: pre-wrap;
}
</style>
