<template>
  <v-row dense class="mb-n2">
    <v-col cols="auto" class="d-flex justify-center">
      <v-checkbox v-model="selected" :value="tripRequest.id" class="my-auto" @change="handleSelected"></v-checkbox>
    </v-col>
    <v-col cols="auto" class="flex-grow-1">
      <trip-request
        :tripRequest="tripRequest"
        :destinations="destinations"
        :specialIndicators="specialIndicators"
        :config="tripRequestConfig"
        :allExpanded="allExpanded"
        :hideQuickActions="true"
      ></trip-request>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { format, fromUnixTime } from 'date-fns';
import TripRequest from './TripRequest.vue';

export default {
  name: 'SelectableTripRequest',
  inject: ['eventHub'],
  components: { TripRequest },
  props: {
    tripRequest: Object,
    value: Array,
    allExpanded: Boolean,
  },
  data() {
    return {
      format,
      fromUnixTime,
      expand: false,
      color: 'primary',
      selected: this.value,
    };
  },
  computed: {
    ...mapGetters('destination', ['destinations']),
    ...mapGetters('config', ['tripRequestConfig', 'specialIndicators']),
  },
  methods: {
    handleSelected(e) {
      this.$emit('input', this.selected);
    },
    getDisplayDate(date) {
      const d = new Date(date);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'MMM d, yyyy');
    },
    handleExpand() {
      this.expand = !this.expand;
    },
  },
  watch: {
    allExpanded(value) {
      this.expand = value;
    },
    value(v) {
      this.selected = v;
    },
  },
};
</script>

<style lang="scss"></style>
